import { images } from './notebook-app/images';

export const articles = [
	{
		title: 'Sending email in static web with EmailJS',
		// desc: 'Different portfolio templates to show your skills, experience, articles etc',
		tags: ['javascript'],
		github_url: 'https://github.com/kfajarbowo/portfolio',
		link: 'https://medium.com/@kfajarbowo/mengirim-email-di-static-web-dengan-emailjs-ed420ffbbd01',
		external: true,
		live: 'https://github.com/kfajarbowo/portfolio',
		published: '9 Aug 2022',
		readTime: '4 min read',
		views: '352',
	},
];
