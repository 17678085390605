import UnindraLogo from 'assets/images/logos/unindra.png';
import BinarLogo from 'assets/images/logos/binar.jpeg';
import RumahCodingLogo from 'assets/images/logos/rumacoding.png';
import SchoolmediaLogo from 'assets/images/logos/schoolmedia.jpg';
import TarunaBakti from 'assets/images/logos/tarunabakti.jpg';

import UniLogo from 'assets/images/logos/fast.png';
import figma from 'assets/images/skills/figma.png';
import vscode from 'assets/images/skills/vscode.png';
import react from 'assets/images/skills/react.png';
import ruby from 'assets/images/skills/ruby.png';
import rails from 'assets/images/skills/rails.png';
import javascript from 'assets/images/skills/javascript.png';
import typescript from 'assets/images/skills/typescript.png';
import html5 from 'assets/images/skills/html-5.png';
import css3 from 'assets/images/skills/css3.png';
import python from 'assets/images/skills/python.png';
import bootstrap from 'assets/images/skills/bootstrap.png';
import postgres from 'assets/images/skills/postgresql.png';
import redis from 'assets/images/skills/redis.png';
import mysql from 'assets/images/skills/mysql.png';
import heroku from 'assets/images/skills/heroku.png';
import docean from 'assets/images/skills/digital-ocean.png';
import aws from 'assets/images/skills/aws.png';

import laravel from 'assets/images/skills/laravel.png';
import mongo from 'assets/images/skills/mongo.png';
import php from 'assets/images/skills/php.png';
import node from 'assets/images/skills/node-js.png';
import materialui from 'assets/images/skills/material-ui.png';
import tailwind from 'assets/images/skills/tailwind.png';

export const companies = [
	{
		title: 'Rumah Coding Indonesia',
		alt: 'PS image',
		role: 'Freelance Trainer',
		skills: ['php', 'laravel', 'codeigniter', 'javascript', 'html', 'css'],
		period: '2021 - Present',
		logo: RumahCodingLogo,
	},
	{
		title: 'Binar Academy',
		alt: 'BT image',
		role: 'Bootcamp - Frontend Javascript',
		skills: [
			'redux',
			'react',
			'javascript',
			'scrum',
			'project management',
			'lead team',
		],
		period: '2023 Feb - 2023 July',
		logo: BinarLogo,
	},
	{
		title: 'Schoolmedia',
		alt: 'CS image',
		role: 'Internship - Web Developer',
		skills: ['php', 'laravel', 'bootstrap', 'javascript', 'css'],
		period: '2019 - 2020',
		logo: SchoolmediaLogo,
	},
];

export const institutes = [
	{
		short_title: 'FAST - NUCES',
		title: 'Indraprasta PGRI University',
		alt: 'Nuces image',
		role: "Bachelor's Degree in Informatics Engineering",
		skills: [
			'project management',
			'web development',
			'data structure',
			'algorithm',
		],
		period: '2020 - 2024',
		startingYear: '2020',
		logo: UnindraLogo,
		awards: [
			{
				title: 'Speed Programming Battle',
				description: 'Won speed programming battle which held at FAST - NUCES.',
				date: 'May 1, 2015',
			},
			{
				title: 'Silver Medal',
				description:
					'This medal was awarded to me by FAST - NUCES for getting a CGPA of 3.9 in 2nd semester.',
				date: 'May 1, 2014',
			},
			{
				title: 'Speed Programming Battle',
				description: 'Won speed programming battle which held at FAST - NUCES.',
				date: 'Nov 1, 2013',
			},
		],
	},
	{
		short_title: 'SMK Taruna Bhakti',
		title: 'Taruna Bhakti Vocational Highschool',
		alt: 'college image',
		role: 'Software Engineering',
		skills: ['technical', 'programming', 'design'],
		period: '2017 - 2020',
		startingYear: '2017',
		logo: TarunaBakti,
	},
];

export const skills = [
	{
		name: 'ReactJS',
		description: 'Intermediate',
		link: 'https://reactjs.org/',
		type: 'development',
		image: react,
	},
	{
		name: 'Javascript',
		description: 'Intermediate',
		link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
		type: 'development',
		image: javascript,
	},
	{
		name: 'Html5',
		description: 'Intermediate',
		link: 'https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5',
		type: 'development',
		image: html5,
	},
	{
		name: 'Css3',
		description: 'Intermediate',
		link: 'https://developer.mozilla.org/en-US/docs/Web/CSS',
		type: 'design',
		image: css3,
	},
	{
		name: 'Bootstrap',
		description: 'Intermediate',
		link: 'https://getbootstrap.com/',
		type: 'design',
		image: bootstrap,
	},
	{
		name: 'MaterialUI',
		description: 'Beginner',
		link: 'https://getbootstrap.com/',
		type: 'design',
		image: materialui,
	},
	{
		name: 'TailwindCSS',
		description: 'Beginner',
		link: 'https://getbootstrap.com/',
		type: 'design',
		image: tailwind,
	},
	{
		name: 'Mysql',
		description: 'Intermediate',
		link: 'https://www.mysql.com/',
		type: 'devops',
		image: mysql,
	},
	{
		name: 'Postgre',
		description: 'Beginner',
		link: 'https://www.heroku.com/',
		type: 'devops',
		image: postgres,
	},
	{
		name: 'Figma',
		description: 'Beginner',
		link: 'https://www.figma.com/',
		type: 'design',
		image: figma,
	},
	{
		name: 'Laravel',
		description: 'Intermediate',
		link: 'https://www.figma.com/',
		type: 'development',
		image: laravel,
	},
	{
		name: 'MongoDB',
		description: 'Beginner',
		link: 'https://www.figma.com/',
		type: 'devops',
		image: mongo,
	},
	{
		name: 'PHP',
		description: 'Intermediate',
		link: 'https://www.figma.com/',
		type: 'development',
		image: php,
	},
	{
		name: 'NodeJS',
		description: 'Beginner',
		link: 'https://www.figma.com/',
		type: 'development',
		image: node,
	},
];
