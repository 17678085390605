// @ts-nocheck
import { Fragment } from 'react';
import {
	VStack,
	useColorModeValue,
	Container,
	IconButton,
	Flex,
	Box,
	Text,
	List,
	ListItem,
	AspectRatio,
	Image,
	Skeleton,
} from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { HiOutlineExternalLink } from 'react-icons/hi';
import Header from 'components/header';
import React from 'react';
import nusantara from 'assets/images/Projects1/fp-binar.png';
import zeeb from 'assets/images/Projects1/zeeb.png';
import akadepoli from 'assets/images/Projects1/akadepoli.png';
import nihongo from 'assets/images/Projects1/nihongo.png';
import video from 'assets/images/Projects1/video.png';
import schoolmedia from 'assets/images/Projects1/schoolmedia.png';

interface Project {
	title: string;
	imageLight: string;
	site: string;
	description: string;
	techStack: string[];
}

interface ProjectProps {
	project: Project;
}
const TURQUOISE = '#06b6d4';

const projects: Project[] = [
	{
		title: 'Nusantara-Fly',
		imageLight: nusantara,
		site: 'https://github.com/kfajarbowo/binairing-app',
		description: `an application website for purchasing and booking airplane tickets, this website App which made to fulfill the Final Project at Binar Academy`,
		techStack: ['React', 'Redux', 'Spring', 'Postgre', 'Bootstrap'],
	},
	{
		title: 'NIHONGO',
		imageLight: nihongo,
		site: 'https://nihongo-gsap-menu.vercel.app/',
		description: `Creative web menu i've created with GSAP animation`,
		techStack: ['React', 'GSAP'],
	},
	{
		title: 'Zeeb-POS',
		imageLight: zeeb,
		site: 'https://github.com/kfajarbowo/zeeb-store',
		description: `Point of sale website with sales invoices`,
		techStack: ['Laravel', 'Vue', 'Bootstrap'],
	},
	{
		title: 'Akadepoli',
		imageLight: akadepoli,
		site: 'https://dribbble.com/shots/14313390-Landing-page-Web-Course-Akadepoli',
		description: `Web design landing page about Web Course`,
		techStack: ['Figma'],
	},
	{
		title: 'Video-SM',
		imageLight: video,
		site: 'https://dribbble.com/shots/14313390-Landing-page-Web-Course-Akadepoli',
		description: `This is a YouTube Video Embed Management Application by copying the url then Post to Frontend`,
		techStack: ['PHP', 'Laravel', 'Bootstrap'],
	},
	{
		title: 'Schoolmedia',
		imageLight: schoolmedia,
		site: '#',
		description: `Schoolmedia Web Landing Page to connect the app with other apps`,
		techStack: ['HTML, CSS, Javascript'],
	},
];

const ProjectsList = () => {
	return (
		<>
			<Header underlineColor={TURQUOISE} mt={0} mb={0}>
				Projects
			</Header>
			<Container maxW={'7xl'} p="12">
				<VStack spacing={8}>
					{projects.map((project, index) => (
						<Fragment key={index}>
							<ProjectLayoutMed project={project} />
							{index % 2 === 0 ? (
								<LeftProjectLayoutLarge project={project} />
							) : (
								<RightProjectLayoutLarge project={project} />
							)}
						</Fragment>
					))}
				</VStack>
			</Container>
		</>
	);
};

const ProjectLayoutMed = ({ project }: ProjectProps) => {
	return (
		<>
			<Flex
				display={['flex', 'flex', 'none']}
				rounded="xl"
				borderColor={useColorModeValue('gray.600', 'gray.700')}
				w="full"
				h="20rem"
				textAlign="left"
				align="start"
				shadow="md"
				_hover={{ border: 'md', shadow: 'lg' }}
				overflow="hidden"
				position="relative"
			>
				<a href={project.site} target="_blank" rel="noopener noreferrer">
					<AspectRatio ratio={1.85 / 1} w="100%" h="100%" rounded="xl">
						<Image
							src={project.imageLight}
							fallback={<Skeleton />}
							sizes="lg"
							width={'full'}
							height={'full'}
							position="absolute"
							rounded="xl"
							objectFit="cover"
							alt="cover image"
							opacity={0.5}
							_hover={{ opacity: 1 }}
						/>
					</AspectRatio>
					<Box
						width={'full'}
						height={'full'}
						position="absolute"
						bg={useColorModeValue('gray.100', 'gray.900')}
						opacity={useColorModeValue('0.5', '1')}
					></Box>
				</a>
				<Box
					width={['full', '70%']}
					rounded="lg"
					my="auto"
					px="6"
					py="3"
					position="relative"
					zIndex="10"
				>
					<Box>
						<a href={project.site} target="_blank" rel="noopener noreferrer">
							<Text
								fontSize="2xl"
								fontWeight="bold"
								color={useColorModeValue('gray.900', 'gray.100')}
							>
								{project.title}
							</Text>
							<Box width="full">
								<Text
									bg={useColorModeValue('gray.200', 'gray.700')}
									rounded="lg"
									align="left"
									p="4"
									fontSize="sm"
								>
									{project.description}
								</Text>
								{project.techStack && (
									<List
										display="flex"
										fontSize="xs"
										justifyContent="start"
										mt="3"
										// eslint-disable-next-line react-hooks/rules-of-hooks
										color={useColorModeValue('gray.900', 'gray.100')}
										fontWeight="bold"
									>
										{project.techStack.map((s, index) => (
											<ListItem key={index} mr="2">
												<i>{s}</i>
											</ListItem>
										))}
									</List>
								)}
							</Box>
						</a>
						<Flex pt={2} mt={1} justifyContent="start">
							<a href={project.site} target="_blank" rel="noopener noreferrer">
								<IconButton
									colorScheme="gray"
									rounded="full"
									size="md"
									aria-label="medal"
									icon={<HiOutlineExternalLink />}
								/>
							</a>
						</Flex>
					</Box>
				</Box>
			</Flex>
		</>
	);
};

const LeftProjectLayoutLarge = ({ project }: ProjectProps) => {
	return (
		<Flex width="full" display={['none', 'none', 'flex']}>
			<Box
				rounded="xl"
				borderColor={useColorModeValue('gray.600', 'gray.700')}
				w="80%"
				h="24rem"
				textAlign="left"
				align="start"
				spacing={4}
				shadow="md"
				_hover={{ border: 'md', shadow: 'lg' }}
				overflow="hidden"
				position="relative"
			>
				<a href={project.site} target="_blank" rel="noopener noreferrer">
					<AspectRatio ratio={1.85 / 1} w="100%" h="100%" rounded="xl">
						<Image
							src={project.imageLight}
							fallback={<Skeleton />}
							sizes="lg"
							width={'full'}
							height={'full'}
							position="absolute"
							rounded="xl"
							objectFit="cover"
							alt="cover image"
							opacity={0.5}
							_hover={{ opacity: 1 }}
						/>
					</AspectRatio>
				</a>
			</Box>
			<Box
				width="40%"
				rounded="lg"
				my="auto"
				zIndex="10"
				ml="-6rem"
				align="right"
			>
				<a
					href={project.site}
					target="_blank"
					rel="noopener noreferrer"
					className="text-right"
				>
					<Text
						fontSize="3xl"
						fontWeight="bold"
						color={useColorModeValue('gray.900', 'gray.100')}
					>
						{project.title}
					</Text>
				</a>
				<Box width="full">
					<Text
						bg={useColorModeValue('gray.200', 'gray.700')}
						rounded="lg"
						align="right"
						p="4"
						fontSize="md"
					>
						{project.description}
					</Text>
					{project.techStack && (
						<List
							display="flex"
							fontSize="sm"
							justifyContent="end"
							mt="3"
							// eslint-disable-next-line react-hooks/rules-of-hooks
							color={useColorModeValue('gray.900', 'gray.100')}
							fontWeight="bold"
						>
							{project.techStack.map((s, index) => (
								<ListItem key={index} mr="3">
									<i>{s}</i>
								</ListItem>
							))}
						</List>
					)}
				</Box>

				<Flex pt={2} mt={1} justifyContent="end">
					<a href={project.site} target="_blank" rel="noopener noreferrer">
						<IconButton
							colorScheme="gray"
							rounded="full"
							size="md"
							aria-label="medal"
							icon={<HiOutlineExternalLink />}
						/>
					</a>
				</Flex>
			</Box>
		</Flex>
	);
};

const RightProjectLayoutLarge = ({ project }: ProjectProps) => {
	return (
		<Flex width="full" display={['none', 'none', 'flex']}>
			<Box
				width="40%"
				rounded="lg"
				my="auto"
				zIndex="10"
				mr="-6rem"
				align="left"
			>
				<a href={project.site} target="_blank" rel="noopener noreferrer">
					<Text
						fontSize="3xl"
						fontWeight="bold"
						color={useColorModeValue('gray.900', 'gray.100')}
					>
						{project.title}
					</Text>
				</a>
				<Box width="full">
					<Text
						bg={useColorModeValue('gray.200', 'gray.700')}
						rounded="lg"
						align="left"
						p="4"
						fontSize="md"
					>
						{project.description}
					</Text>
					{project.techStack && (
						<List
							display="flex"
							fontSize="sm"
							justifyContent="start"
							mt="3"
							// eslint-disable-next-line react-hooks/rules-of-hooks
							color={useColorModeValue('gray.900', 'gray.100')}
							fontWeight="bold"
						>
							{project.techStack.map((s, index) => (
								<ListItem key={index} mr="3">
									<i>{s}</i>
								</ListItem>
							))}
						</List>
					)}
				</Box>

				<Flex pt={2} mt={1} justifyContent="start">
					<a href={project.site} target="_blank" rel="noopener noreferrer">
						<IconButton
							colorScheme="gray"
							rounded="full"
							size="md"
							aria-label="medal"
							icon={<HiOutlineExternalLink />}
						/>
					</a>
				</Flex>
			</Box>
			<Box
				rounded="xl"
				borderColor={useColorModeValue('gray.600', 'gray.700')}
				w="80%"
				h="24rem"
				textAlign="left"
				align="start"
				spacing={4}
				shadow="md"
				_hover={{ border: 'md', shadow: 'lg' }}
				overflow="hidden"
				position="relative"
			>
				<a href={project.site} target="_blank" rel="noopener noreferrer">
					<AspectRatio ratio={1.85 / 1} w="100%" h="100%" rounded="xl">
						<Image
							src={project.imageLight}
							fallback={<Skeleton />}
							sizes="lg"
							width={'full'}
							height={'full'}
							position="absolute"
							rounded="xl"
							objectFit="cover"
							alt="cover image"
							opacity={0.5}
							_hover={{ opacity: 1 }}
						/>
					</AspectRatio>
				</a>
			</Box>
		</Flex>
	);
};

export default ProjectsList;
