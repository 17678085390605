// @ts-nocheck
import * as React from 'react';
import {
	FiPackage,
	FiHome,
	FiEdit2,
	FiUsers,
	FiBarChart2,
	FiBookOpen,
	FiBookmark,
} from 'react-icons/fi';
import { VStack, Heading, Box, Link, LinkProps } from '@chakra-ui/react';
import { TimelineItem } from './Timeline';
import { PageSlideFade } from './page-transitions';
import { Link as NavLink } from 'react-router-dom';
import Header from './header';

interface ExternalLinkProps extends LinkProps {
	url: string;
	linkProps?: LinkProps;
	text: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
	url,
	linkProps,
	text,
	...props
}) => {
	return (
		<Link href={url} {...linkProps} {...props}>
			{text}
		</Link>
	);
};

const InternalLink: React.FC<ExternalLinkProps> = ({
	url,
	linkProps,
	text,
	...props
}) => {
	return (
		<Link as={NavLink} to={url} {...linkProps} {...props}>
			{text}
		</Link>
	);
};
const Achievements = () => {
	return (
		<PageSlideFade>
			<Box mb={6}>
				<Header mt={0} mb={0} underlineColor={''}>
					Developer Story
				</Header>
			</Box>
			<VStack textAlign="start" align="start" mb={5}>
				<Box>
					<Heading fontSize="2xl" fontWeight="600" my={5}>
						2023
					</Heading>
					<Box>
						<TimelineItem icon={FiBookOpen} skipTrail>
							Participated in Binar Academy's Bootcamp program on Frontend
							Javascript . {''}I learned a lot here, especially as a team leader
							and then managing my team of 4 to complete this Final Project as a
							graduation requirement at Binar.
							{/* <ExternalLink
								color={'blue.200'}
								url="https://github.com/MA-Ahmad/portfolio2"
								text={'source on Github'}
								target="_blank"
							/> */}
						</TimelineItem>
					</Box>
				</Box>

				<Box>
					<Heading fontSize="2xl" fontWeight="600" my={5}>
						2022
					</Heading>
					<Box>
						<TimelineItem icon={FiBookOpen}>
							Participated in the #juaragcp season 8 event then completed the 14
							lab quests provided and got a swag{' '}
							<ExternalLink
								color={'blue.200'}
								url="https://www.cloudskillsboost.google/public_profiles/0742bbfe-e8a1-412d-afca-8d07fff3124c"
								text={'Goolge Cloud Profile'}
								target="_blank"
							/>
						</TimelineItem>
						<TimelineItem icon={FiBookOpen} skipTrail>
							Get Certification BNSP - Junior Web Developer
						</TimelineItem>
					</Box>
				</Box>
				<Box>
					<Heading fontSize="2xl" fontWeight="600" my={5}>
						2021
					</Heading>
					<Box>
						<TimelineItem icon={FiBookOpen}>
							Participated in the #juaragcp season 5 event then completed the 14
							lab quests provided and got a swag{' '}
							<ExternalLink
								color={'blue.200'}
								url="https://www.cloudskillsboost.google/public_profiles/0742bbfe-e8a1-412d-afca-8d07fff3124c"
								text={'Goolge Cloud Profile'}
								target="_blank"
							/>
						</TimelineItem>
						<TimelineItem icon={FiPackage}>
							My first experience taught others{' '}
							{/* <InternalLink color={'blue.200'} url="/blog" text={'Blog'} /> */}
						</TimelineItem>
						<TimelineItem icon={FiBookmark} skipTrail>
							Get my first job as freelance trainer programming{' '}
							{/* <InternalLink
								color={'blue.200'}
								url="/open-source"
								text={'9 open-source repositories'}
							/> */}
						</TimelineItem>
						{/* <TimelineItem icon={FiHome} skipTrail>
							Rebuilt my portfolio website with React, ChakraUI and
							Framer-motion,{' '}
							<ExternalLink
								color={'blue.200'}
								url="https://github.com/MA-Ahmad/portfolio"
								text={'source on Github'}
								target="_blank"
							/>
							.
						</TimelineItem> */}
					</Box>
				</Box>
				<Box>
					<Heading fontSize="2xl" fontWeight="600" my={5}>
						2020
					</Heading>
					<Box>
						<TimelineItem icon={FiBookOpen} skipTrail>
							Graduated from vocational high school and went on to university.{' '}
							{/* <ExternalLink
								color={'blue.200'}
								url="https://github.com/MA-Ahmad/portfolio2"
								text={'source on Github'}
								target="_blank"
							/> */}
						</TimelineItem>
					</Box>
				</Box>
				<Box>
					<Heading fontSize="2xl" fontWeight="600" my={5}>
						2019
					</Heading>
					<Box>
						<TimelineItem icon={FiHome} skipTrail>
							My first experience as Internship Web Developer{' '}
							{/* <ExternalLink
								color={'blue.200'}
								url="https://github.com/MA-Ahmad/portfolio2"
								text={'source on Github'}
								target="_blank"
							/> */}
							.
						</TimelineItem>
					</Box>
				</Box>
			</VStack>
		</PageSlideFade>
	);
};

export default Achievements;
