import blog1 from '../assets/images/repositories/blog_1.png';
import blog2 from '../assets/images/repositories/blog_2.png';
import blog3 from '../assets/images/repositories/blog_3.png';
import portfolio from '../assets/images/repositories/portfolio.png';
import portfoliov2 from '../assets/images/repositories/portfolio-v2.png';
import quickstart from '../assets/images/repositories/quickstart.jpeg';
import cardportfolio from '../assets/images/repositories/cardportfolio.jpeg';
import imgGallery from '../assets/images/repositories/image_gallery.png';
import notebook from '../assets/images/repositories/notebook_app.png';
import devto from '../assets/images/repositories/devto.png';

export const repositories = [
	// {
	//   title: "myPortfolio",
	//   description:
	//     "This portfolio built by using Next.js, ChakraUi and Typescript.",
	//   cover: portfoliov2,
	//   technologies: ["Nextjs", "chakraUi"],
	//   url: "https://github.com/MA-Ahmad/myPortfolio",
	//   live: "https://mahmad.me",
	//   blurHash: "L25#he^nryxc^-w$V{V_56bqx[M{",
	//   stars: "140",
	//   fork: "31"
	// },
	// {
	//   title: "Portfolio",
	//   description: "Personal portfolio app to show my skills and experience.",
	//   cover: portfoliov2,
	//   technologies: ["react", "chakraUi"],
	//   url: "https://github.com/MA-Ahmad/portfolio",
	//   live: "https://ma-ahmad.github.io/portfolio",
	//   blurHash: "L25#he^nryxc^-w$V{V_56bqx[M{",
	//   stars: "81",
	//   fork: "14"
	// },
	// {
	//   title: "Dev.to clone",
	//   description:
	//     "This portfolio built by using Next.js, ChakraUi and Typescript.",
	//   cover: devto,
	//   technologies: ["Nextjs", "chakraUi"],
	//   url: "https://github.com/MA-Ahmad/dev.to-clone",
	//   live: "https://dev-to-clone-ma.vercel.app",
	//   blurHash: "LMOWg4wIS$WBH?sAkCR*?^bcnNoy",
	//   stars: "64",
	//   fork: "8"
	// },
	// {
	//   title: "Notebook",
	//   description:
	//     "This is a notebook app which will help you to crate notes for your daily work.",
	//   cover: notebook,
	//   technologies: ["react", "typescript", "chakraUI"],
	//   url: "https://github.com/MA-Ahmad/notebook",
	//   live: "https://ma-ahmad.github.io/notebook",
	//   blurHash: "L4S~x5xb~q$*~WR6MykCx^Vt4TtP",
	//   stars: "18",
	//   fork: "7"
	// },
	// {
	//   title: "Portfolio2",
	//   description: "Personal portfolio app to show my skills and experience.",
	//   cover: portfolio,
	//   technologies: ["react", "chakraUi"],
	//   url: "https://github.com/MA-Ahmad/portfolio2",
	//   live: "https://ma-ahmad.github.io/portfolio2",
	//   blurHash: "L35O{d~XeoMyDhRPxv%Ms=xunmRQ",
	//   stars: "16",
	//   fork: "6"
	// },
	// {
	//   title: "Blog App",
	//   description:
	//     "A React Blog App built by using libraries like ChakraUI, Formik.",
	//   technologies: ["react", "chakraUi", "formik"],
	//   cover: blog1,
	//   url: "https://github.com/MA-Ahmad/reactBlog",
	//   live: "https://ma-ahmad.github.io/reactBlog",
	//   blurHash: "LMMaw^IV~pxu00%LRjNGAIIVadt6",
	//   stars: "13",
	//   fork: "2"
	// },
	// {
	//   title: "Blog App (front-end)",
	//   description: "React frontend of a blog app with rails on backend.",
	//   cover: blog2,
	//   technologies: ["react", "rails", "chakraUi"],
	//   url: "https://github.com/MA-Ahmad/blog-app-react-frontend",
	//   live: "https://blog-frontend-react.herokuapp.com",
	//   blurHash: "LSN1AcH?~Wtl00={M{NG0eIoj]xa",
	//   stars: "12",
	//   fork: '3'
	// },
	// {
	//   title: "Card portfolio",
	//   description: "A portfolio template.",
	//   cover: cardportfolio,
	//   technologies: ["react", "typescript", "chakraUI"],
	//   url: "https://github.com/MA-Ahmad/card-portfolio",
	//   live: "https://ma-ahmad.github.io/card-portfolio",
	//   blurHash: "L15#hiax00og^Uf65Nj]oPfkWRf6",
	//   stars: "9",
	//   fork: "2"
	// },
	// {
	//   title: "Rails template",
	//   description: "A rails app template built with tailwindcss.",
	//   cover: quickstart,
	//   technologies: ["rails", "tailwindCss"],
	//   url: "https://github.com/MA-Ahmad/quickstart-rails-tailwind",
	//   live: "https://quickstart-rails-tailwind.herokuapp.com",
	//   blurHash: "LLPGmg?wRPS~D%D%ozxa00?G-;R%",
	//   stars: "8"
	// },
	// {
	//   title: "Blogify",
	//   description: "A simple react+rails(RR) CRUD app with tailwindcss.",
	//   cover: blog3,
	//   technologies: ["rails", "tailwindCss"],
	//   url: "https://github.com/MA-Ahmad/react_rails_blog",
	//   live: "https://react-on-rails-blog.herokuapp.com",
	//   blurHash: "L6O;6=00M|og00_4_4s:E9-oxVoL",
	//   stars: "4"
	// },
	// {
	//   title: "Image Gallery",
	//   description:
	//     "Simple gallery with images from the pixabay API. Tailwind css is built into workflow with postcss and autoprefixer.",
	//   cover: imgGallery,
	//   technologies: ["react", "tailwindCss"],
	//   url: "https://github.com/MA-Ahmad/react-image-gallery",
	//   blurHash: "LMOWg4wIS$WBH?sAkCR*?^bcnNoy",
	//   stars: "3"
	// },
	// {
	//   title: "Blog App (back-end)",
	//   description: "Rails backend api for blog app with react on frontend.",
	//   technologies: ["ruby", "rails"],
	//   url: "https://github.com/MA-Ahmad/blog-app-rails-backend",
	//   blurHash: "L4S~x5xb~q$*~WR6MykCx^Vt4TtP",
	//   stars: '4',
	//   fork: '1'
	// }
	// {
	//   title: "Video-to-text",
	//   description: "Convert a video to text using ruby.",
	//   cover: "",
	//   technologies: ["ruby"],
	//   url: "https://github.com/MA-Ahmad/Video-to-text",
	//   blurHash: "L4S~x5xb~q$*~WR6MykCx^Vt4TtP",
	//   stars: "3"
	// },
	// {
	//   title: "Authentication API",
	//   description:
	//     "Simple Authentication API that can be used with any front-end app like React, Vue, Angular app.",
	//   technologies: ["ruby", "rails"],
	//   url: "https://github.com/MA-Ahmad/rails-authentication-app",
	//   blurHash: "L4S~x5xb~q$*~WR6MykCx^Vt4TtP",
	//   stars: "2"
	// }
];
