import React from 'react';
import {
	FaGithub,
	FaDev,
	FaLinkedin,
	FaQuora,
	FaTwitter,
	FaInstagram,
} from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';

const siteConfig = {
	copyright: `Copyright © ${new Date().getFullYear()} Muhammad Ahmad. All Rights Reserved.`,
	author: {
		name: 'Muhammad Ahmad',
		accounts: [
			{
				url: 'https://github.com/kfajarbowo',
				label: 'Github Account',
				type: 'gray',
				icon: <FaGithub />,
			},
			{
				url: 'https://linkedin.com/in/kfajarbowo',
				label: 'LinkedIn Account',
				type: 'linkedin',
				icon: <FaLinkedin />,
			},
			{
				url: 'https://www.instagram.com/kfajarbowo/',
				label: 'Quora Account',
				type: 'red',
				icon: <FaInstagram />,
			},
			{
				url: 'mailto:kfajarbowo12@gmail.com',
				label: 'Mail ahmad',
				type: 'gray',
				icon: <FiMail />,
			},
		],
	},
};

export default siteConfig;
